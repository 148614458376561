import * as Tealium from '@/modules/tealium/Tealium'
import * as supportPageUtilities from '@/modules/tealium/utilities/pages/support'

const SUBSCRIBE_CLICK_EVENT = 'api subscribe click',
  SUBSCRIBE_FORM_SUBMIT = 'form submit',
  ADD_TO_PROJECT_CLICK_EVENT = 'add to project click',
  SOLUTION_AREA_FIND_BUTTON_EVENT = 'find a partner click',
  SOLUTION_AREA_BECOME_BUTTON_EVENT = 'become a partner click',
  FORM_START_TRANSACTION = 'form start',
  LOGIN_KEY = 'login'

export function submitSupportTicketHandler() {
  try {
    let tealiumData = Tealium.generateReportingData(),
      onClickEventArr = tealiumData.onClickEvent.split(':')

    onClickEventArr.pop()
    onClickEventArr.push(SUBSCRIBE_CLICK_EVENT)

    tealiumData.onClickEvent = onClickEventArr.join(':')
    tealiumData.transactionStatus = SUBSCRIBE_FORM_SUBMIT

    Tealium.reportDataClickEvent(tealiumData)
  } catch (error) {
    console.log(error)
  }
}

export function radioBtnClickHandler() {
  try {
    let tealiumData = Tealium.generateReportingData()

    tealiumData.onClickEvent = `${tealiumData.currentPage}:api initiation click`
    delete tealiumData.currentPage
    tealiumData.transactionStatus = FORM_START_TRANSACTION

    Tealium.reportDataClickEvent(tealiumData)
    supportPageUtilities.removeClickHandlers()
  } catch (error) {
    console.log(error)
  }
}

export function displayLoginModalHandler() {
  try {
    let tealiumData = Tealium.generateReportingData()

    tealiumData.onClickEvent = `${tealiumData.currentPage}:${LOGIN_KEY}:${FORM_START_TRANSACTION} click`
    tealiumData.subSiteSection = LOGIN_KEY
    tealiumData.transactionStatus = FORM_START_TRANSACTION
    tealiumData.formName = `${LOGIN_KEY}`
    delete tealiumData.currentPage

    Tealium.reportDataClickEvent(tealiumData)
  } catch (error) {
    console.log(error)
  }
}

export function addToProjectHandler() {
  try {
    let tealiumData = Tealium.generateReportingData(),
      currentPageArr = tealiumData.currentPage.split(':'),
      product = currentPageArr[currentPageArr.length - 1]

    currentPageArr.push(ADD_TO_PROJECT_CLICK_EVENT)
    tealiumData.onClickEvent = currentPageArr.join(':')
    tealiumData.subSiteSection = product
    delete tealiumData.currentPage

    Tealium.reportDataClickEvent(tealiumData)
  } catch (error) {
    console.log(error)
  }
}

export function solutionAreaTreasuryManagmentHandler(button: any) {
  try {
    let tealiumData = Tealium.generateReportingData(),
      findButton = button.currentTarget.innerHTML.includes('Find'),
      becomeButton = button.currentTarget.innerHTML.includes('Become')

    if (!findButton && !becomeButton) {
      console.log('solution area treasury managment click handler not found')
    }

    if (findButton) {
      tealiumData.onClickEvent = `${tealiumData.currentPage}:${SOLUTION_AREA_FIND_BUTTON_EVENT}`
    }

    if (becomeButton) {
      tealiumData.onClickEvent = `${tealiumData.currentPage}:${SOLUTION_AREA_BECOME_BUTTON_EVENT}`
    }

    delete tealiumData.currentPage

    Tealium.reportDataClickEvent(tealiumData)
  } catch (error) {
    console.log(error)
  }
}
